

















































import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
  name: 'EmailStep',
  components: {
    BaseInput: () => import('./BaseInput.vue'),
  },
})
export default class EmailStep extends Vue {
  @Prop({ type: String, required: true }) readonly value!: string
  @Prop({ type: String, required: true }) readonly err!: string
  /**
   * Whether a user can remove the email on this step (if it's set),
   * or choose not to set an email at all (when setting up 2fa)
   */
  @Prop({ type: Boolean, default: false }) readonly isRemoveTarget!: boolean

  get removeTargetLabel (): string {
    return this.isRemoveTarget
      ? this.$t('auth.twoFactorAuth.removeMailAction').toString()
      : this.$t('auth.twoFactorAuth.noneMailAction').toString()
  }

  get isError (): boolean {
    return !!this.err
  }

  get inputLabel (): string {
    return this.isError
      ? this.err
      : this.$t('auth.twoFactorAuth.inputMailLabel').toString()
  }

  withoutEmail () {
    this.$emit('without-email')
  }
}
